import { makeStyles } from "@mui/styles";

const useGlobalStyles = makeStyles({
  whiteText: {
    color: "white",
  },
  square: {
    borderRadius: "0px !important",
  },
});

export default useGlobalStyles;
